import styled from "styled-components"
import { Link } from "gatsby"

export const FooterWrapper = styled.footer`
  background: #f6f8f9;
  color: #3d4145;
  flex: 0 0 auto;
  padding: 90px 0 0;

  .b-footer-box {
    text-align: start;
  }
`

export const LogoWrapper = styled(Link)`
  display: block;
  padding-bottom: 20px;
`
