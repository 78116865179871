/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import { useLocation } from "@reach/router"

function SEO({ description, lang, meta, title }) {
  const {
    site: {
      siteMetadata: {
        title_ar,
        author,
        title_en,
        description_ar,
        description_en,
        tags_ar,
        tags_en,
        siteUrl,
      },
    },
  } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title_ar
            title_en
            description_ar
            description_en
            tags_ar
            tags_en
            author
            siteUrl
          }
        }
      }
    `
  )
  const { pathname } = useLocation()
  const isAr = lang === "ar"
  const mainTitle = isAr ? title_ar : title_en
  const metaKeywords = isAr ? tags_ar : tags_en
  const mainDescription = isAr ? description_ar : description_en
  const metaDescription = description || mainDescription
  const dir = isAr ? "rtl" : "ltr"
  const canonical = pathname ? `${siteUrl}${pathname}` : null

  return (
    <Helmet
      htmlAttributes={{
        lang,
        dir,
      }}
      title={title}
      titleTemplate={`%s | ${mainTitle}`}
      meta={[
        {
          name: "facebook-domain-verification",
          content: "1sdokboj3otgrnep1km4acbkextoq6",
        },
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title || mainTitle,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: author,
        },
        {
          name: `twitter:title`,
          content: title || mainTitle,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: "keywords",
          content: metaKeywords.join(", "),
        },
      ].concat(meta)}
      link={
        canonical
          ? [
              {
                rel: "canonical",
                href: canonical,
              },
            ]
          : []
      }
    />
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO
