import loadable from "@loadable/component"
import React, { useState } from "react"
import PropTypes from "prop-types"

import "./layout.css"
import "./layout-rtl.css"
import "./index.css"
import useLang from "../hooks/useLang"
import "react-responsive-carousel/lib/styles/carousel.min.css"
import SEO from "./seo"
import Footer from "./Footer"
import { Styled } from "theme-ui"

const Header = loadable(() => import("./Header"))
const Sidebar = loadable(() => import("./Sidebar"))

if (typeof window !== "undefined") {
  require("smooth-scroll")('a[href*="#"]')
}

const Layout = ({ children, title, description }) => {
  const lang = useLang()
  const [visible, setVisible] = useState(false)

  const handleToggle = () => setVisible(!visible)

  return (
    <Styled.root>
      <SEO description={description} title={title} lang={lang} />
      <Header lang={lang} />
      <div>
        <Sidebar toggleSidebar={handleToggle} visible={visible} lang={lang} />
        <main>{children}</main>
        <Footer lang={lang} />
      </div>
    </Styled.root>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
