import React from "react"
import { Link } from "gatsby"
import logo from "../../images/logo.png"
import "./style.css"
import { FooterWrapper, LogoWrapper } from "./footer.style"
import footerData from "../../../content/data/footer.yml"
import Icon from "react-icons-kit"
import { facebook } from "react-icons-kit/fa/facebook"
import { BsTwitterX } from "react-icons/bs"
import { instagram } from "react-icons-kit/fa/instagram"
import { linkedin } from "react-icons-kit/fa/linkedin"
import { whatsapp } from "react-icons-kit/fa/whatsapp"
import useLang from "../../hooks/useLang"

const ListItem = ({ label, icon, link }) => (
  <li>
    <a
      style={{ padding: 10, marginInlineEnd: 10 }}
      rel="noopener noreferrer"
      href={link}
      aria-label={label}
      title={label}
      target="_blank"
    >
      <Icon icon={icon} />
    </a>
  </li>
)

const Footer = () => {
  const lang = useLang()
  const isAr = lang === "ar"
  const menu = []
  const socialAccounts = []

  footerData.columns.forEach((column, index) => {
    if (column.menu)
      menu.push(
        <div key={index + column.titleobj[lang]}>
          <ul className="list-unstyled d-flex flex-wrap">
            {column.menu.map(item => (
              <li key={item.uri + item.titleobj[lang]}>
                <Link
                  style={{
                    padding: 20,
                    width: 120,
                    display: "inline-block",
                  }}
                  to={`${isAr ? "/ar" : ""}${item.uri}`}
                >
                  {item.titleobj[lang]}
                </Link>
              </li>
            ))}
          </ul>
        </div>
      )
    else
      socialAccounts.push(
        <div style={{ margin: 30 }}>
          <span className="footer-box-title">{column.titleobj[lang]}</span>
          <p>
            <i className={column.workingHours.icon}></i>{" "}
            {column.workingHours.textObj[lang]}
          </p>
          <div className="">
            <ul className="list-inline">
              <ListItem
                label="social facebook account"
                icon={facebook}
                link={column.social.facebook}
              />
              {!!column.social.twitter && (
                <a
                  style={{ padding: 10, marginInlineEnd: 10 }}
                  rel="noopener noreferrer"
                  href={column.social.twitter}
                  aria-label={"social twitter account"}
                  title={"social twitter account"}
                  target="_blank"
                >
                  <BsTwitterX />
                </a>
              )}
              <ListItem
                label="social instagram account"
                icon={instagram}
                link={column.social.instagram}
              />
              <ListItem
                label="social linkedin account"
                icon={linkedin}
                link={column.social.linkedin}
              />
              <ListItem
                label="social whatsapp account"
                icon={whatsapp}
                link={column.social.whatsapp}
              />
            </ul>
          </div>
        </div>
      )
  })
  return (
    <FooterWrapper>
      <div className="container">
        <LogoWrapper to="/">
          <img src={logo} alt="benchmark logo" />
        </LogoWrapper>
        <div className="row">
          <div
            style={{ marginTop: 20 }}
            className="col-sm-12 col-md-6"
            id="mobile_fix"
          >
            <p>{footerData.about[lang]}</p>
            <p className="copy">
              {footerData.copy[lang]}
              <sup>®</sup>
            </p>
          </div>
          <div className="col-sm-12 col-md-6">{menu}</div>
          <div>{socialAccounts}</div>
        </div>
      </div>
    </FooterWrapper>
  )
}
export default Footer
